<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions/extreme">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Extreme-chiusa-rev.1-210609-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Extreme
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions/contactst">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Contact-ST5-rev.1-210609-def-1024x696.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Contact ST5
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions/contactdwf5">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/07/Contact-DWF5-Rev.6-210610-DEF-1024x696.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Contact ST5DWF5
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions/contacttr">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Contact-TR-rev.1-210609-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              Contact TR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/automatic_solutions/lynxds5">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Lynx-DS5-davanti-rev.1-210611-1536x1045.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Lynx DS5
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        
        
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>